import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Layout from '@components/layout'

const SludgeJudgePage = () => {
  return (
    <Layout pageTitle="Sludge Judge">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage
                className="hero-image"
                src="../../../media/truck-between-two-lagoons.jpeg"
                alt=""
                aspectRatio={3.75 / 1}
                layout="fullWidth"
              />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>Services</h2>
                <h1>Sludge Judge</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <Container fluid="xxl" className="section default-text">
          <Row className="align-items-center" md={2} xs={1}>
            <Col md={{ order: 'first' }} xs={{ order: 'last' }}>
              <StaticImage
                src="../../../media/two-men-in-a-boat.jpeg"
              />
            </Col>
            <Col>
              <h3>What is a sludge judge?</h3>
              <p>
                A sludge judge is a tool that judges the depth of sludge in a lagoon. The tool also removes a sample of sludge that gets sent to a lab for testing. Our judges map out a grid pattern on the lagoon, and take samples from each point. These samples are then combined for testing. Throughout the process the judges document on their map the depth of each sample. This type of inspection lets the lagoon owner know when to expect the need for a lagoon clean out, or any issues they may be having with the lagoon.
              </p>
            </Col>
          </Row>
        </Container>
        <Container><hr /></Container>
        <Container fluid="xxl" className="section default-text">
          <Row className="align-items-center" md={2} xs={1}>
            <Col>
              <h3>The Process</h3>
              <p>
                A kayak, Sludge Judge and handheld GPS are used during the survey. The kayak was used to paddle out to the spots relatively outlined on the map and put the Sludge Judge into the lagoon as deep as possible. The Sludge Judge is then pulled up and recorded the total depth of liquid in the column and the total sludge depth (where the liquid went from clear to dark). GPS points of each location sampled are taken and recorded. 
              </p>
              <p>
                Samples are taken throughout the lagoon to be analyzed in a lab to ensure the material meets all of the parameters outlined in O.Reg 267/03 (Nutrient Management Regulations) for application on land.
              </p>
            </Col>
            <Col>
              <StaticImage
                src="../../../media/corner-of-lagoon.jpeg"
              />
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl" className="section default-text">
        <Container><hr/></Container>
          <Row className="align-items-center" md={2} xs={1}>
            <Col md={{ order: 'first' }} xs={{ order: 'last' }}>
              <StaticImage
                src="../../../media/end-of-a-broom.jpg"
              />
            </Col>
            <Col>
              <h3>Material Results</h3>
              <p>
                The material is analyzed for all parameters in outlined in O.Reg 267/03.
              </p>
              <p>
                If the material passes all parameters outlined in O.Reg 267/03. The material would provide a beneficial use if land applied. The results test for heavy metals levels and more. If the material is land applied at 130m3/ha, the farmers could receive approximately 80 kg/ha of nitrogen, 95 kg/ha of phosphorus and 14 kg/ha of potassium (results taken from a recent sludge judge).
              </p>
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  )
}

export default SludgeJudgePage